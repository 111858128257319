import React, { Component } from 'react';
import './MarketCap.css';
import db from '../firestore';
import { getSymbol } from '../helpers/Helper';
import Footer from '../Components/Footer';

class CryptoTable extends Component {
  state = {
    loadingTable: true,
    MFxs: [
      {
        change: 0,
        asset: '',
        MFx: '',
        price: 0,
        symbol: '',
        volume: 0
      }
    ],
    icons: []
  };

  getMfxs = () => {
    db.collection('MFx_table')
      .orderBy('MFx', 'desc')
      .limit(50)
      .onSnapshot(snapshots => {
        const MFxs = [];
        snapshots.forEach(snapshot => {
          MFxs.push(snapshot.data());
        });
        this.setState({ MFxs, loadingTable: false });
      });
  };

  componentDidMount() {
    let icons = [];
    db.collection('CFN_icons').onSnapshot(snapshot => {
      this.setState({ loadingTable: true })
      snapshot.forEach(data => {
        icons.push(data.data());
      });
      this.setState({ icons: icons });
      icons = [];
      this.getMfxs();
    });
  }
  render() {
    const { MFxs, loadingTable } = this.state;
    if (loadingTable) {
      return (  
        <img
          src="https://loading.io/spinners/typing/lg.-text-entering-comment-loader.gif" alt="loader"
        />       
      );
    }
    return (
      <>
        <div className="cryptoTable">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="center">No</th>
                  <th>Asset</th>
                  <th>Price</th>
                  <th>% Change</th>
                  {/* <th>24h High</th> */}
                  {/* <th>24h Low</th> */}
                  <th>MFx</th>
                </tr>
              </thead>
              <tbody>
                {MFxs.map((coin, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 1 ? 'striped' : undefined}
                  >
                    <td className="center">{index + 1}</td>
                    <td>
                      <div className="cryptoTable__asset">
                        <img
                          src={
                            getSymbol('CFIx' + coin.Symbol, this.state.icons)
                              ? getSymbol(
                                  'CFIx' + coin.Symbol,
                                  this.state.icons
                                )
                              : 'https://cdn2.iconfinder.com/data/icons/bitcoin-and-mining/44/trade-512.png'
                          }
                          alt="coinImg"
                        />
                        <p className="asset">{coin.Asset}</p>
                      </div>
                    </td>
                    <td>$ {coin.Price}</td>
                    <td
                      className={`text--${
                        coin['% Change (24h)'] > 0 ? 'green' : 'red'
                      }`}
                    >
                      {coin['% Change (24h)'] < 0 && (
                        <i className="fas fa-minus minus-sign" />
                      )}
                      {coin['% Change (24h)'] < 0
                        ? (coin['% Change (24h)'] * -100).toFixed(2)
                        : (coin['% Change (24h)'] * 100).toFixed(2) }
                      %
                    </td>
                    {/* <td>$ {0}</td> */}
                    {/* <td>$ {coin.Volume}</td> */}
                    <td>$ {coin.MFx.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default CryptoTable;
