import db, { storage } from '../firestore';

export function getYoutubeId(str) {
  if (str.includes('http://') || str.includes('https://')) {
    return str.split('?v=')[1];
  }
  return str;
}

export function getTwitterId(str) {
  return str.split('/status/')[1];
}

export const tagedIcon = (tag, icons) => {
  const icon = icons.filter(icon => icon.name === tag);
  return icon.length > 0 ? icon[0].iconURL : null;
};

export const getSymbol = (tag, icons) => {
  const symbol = icons.filter(icon => icon.symbol === tag);
  return symbol.length > 0 ? symbol[0].iconURL : null;
};

export const getCryptoName = (tag, icons) => {
  const symbol = icons.filter(icon => icon.symbol === tag);
  return symbol.length > 0 ? symbol[0].name : null;
};

export const getLiveEvent = (tag, icons) => {
  const liveEvents = icons.filter(icon => icon.liveEvent === tag);
  return liveEvents.length > 0 ? liveEvents[0].liveEvent : null;
}

export const getCryptoName2 = (tag, next) => {
  let icons = [];
  db.collection('CFN_icons').onSnapshot(snapshot => {
    snapshot.forEach(data => {
      icons.push(data.data());
    });
    const symbol = icons.filter(icon => icon.symbol === tag);
    symbol.length > 0 ? next(symbol[0].name) : next(null);
  });
};

export const oneIcon = (tags, icons) => {
  let res = [];
  tags.forEach(tag => {
    icons.forEach(icon => {
      if (icon.name === tag) {
        res.push(icon);
      }
    });
  });
  if (res.length > 0) {
    return res[0].iconURL;
  } else {
    return null;
  }
};

export function getImage(url, cb) {
  // return 'ooio'
  // console.log(1111)
  const data = storage.refFromURL(url);
  data.getDownloadURL().then(res => cb(res));
  // console.log(res, 'res');
  // console.log('----====+_+_+');
  // return 'he123heo'
  // // console.log(url , '09')
  // // console.log('START')
  // // url = 'https://images.pexels.com/photos/426893/pexels-photo-426893.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500'
  // // return url
}

export function getTwitterProps(str = '') {
  return {
    profile: str.split('/')[3],
    slug: str.split('/')[5]
  };
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const CFConvert = str => {
  if (str) {
    const res = str.replace('CFI', 'CF');
    return res;
  }
};

export const httpCheck = url => {
  if (!url || url == 'NaN') {
    return '#';
  }
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    return 'https://' + url;
  }
  return url;
};

export const getMonth = (month) => {
  switch(month) {
    case 0: return 'Jan';
    case 1: return 'Feb';
    case 2: return 'Mar';
    case 3: return 'Apr';
    case 4: return 'May';
    case 5: return 'Jun';
    case 6: return 'Jul';
    case 7: return 'Aug';
    case 8: return 'Sept';
    case 9: return 'Oct';
    case 10: return 'Nov';
    case 11: return 'Des';
    default: return 'Invalid Month'
  }
}
