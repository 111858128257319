import React, { Component } from 'react';
import Navbar from './Components/Navbar';
import MarketCap from './Containers/MarketCap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <Navbar />
          <Switch>
            <Route exact path="/" component={MarketCap} />
          </Switch>
        </>
      </Router>
    );
  }
}

export default App;
